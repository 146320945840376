<template>
    <div class="page">
        <div class="title">员工借支扣减</div>
		
        <!-- filter -->
        <div class="filter_box">
            <div class="filter_search">
                <!-- picker -->
                <el-date-picker
                    v-model="search.date"
                    type="month"
                    value-format="yyyy-MM"
                ></el-date-picker>

                <!-- input -->
                <el-input
                    class="filter_search_input"
                    placeholder="请输入关键词"
                    v-model="search.keyword"
                    clearable
                >
                </el-input>
                <div class="custom_button no_select" @click="toSearch">搜索</div>
            </div>
            <div class="filter_btns">
                <div class="custom_button no_select">导出</div>
            </div>
        </div>

        <!-- table -->
        <el-table
            id="table"
            :data="tableData"
            :header-cell-style="tableHeaderStyle"
            :cell-style="tableCellStyle"
            style="width: 100%;"
        >
            <el-table-column prop="staff.job_number" label="工号"></el-table-column>
            <el-table-column prop="staff.real_name" label="姓名"></el-table-column>
            <el-table-column prop="staff.department_data.department_name" label="部门"></el-table-column>
            <el-table-column prop="back_amount" label="归还金额"></el-table-column>
            <el-table-column prop="back_date" label="归还时间"></el-table-column>
            <el-table-column prop="back_type_value" label="归还方式"></el-table-column>
            <el-table-column prop="salary_borrow.borrow_amount" label="借支金额"></el-table-column>
            <el-table-column prop="salary_borrow.borrow_time" label="借支时间"></el-table-column>
        </el-table>
        <el-pagination
            layout="prev, pager, next"
            :total="page.total"
            :page-size="page.size"
            :current-page.sync="page.page"
            @current-change="loadData"
        >
        </el-pagination>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            search: {
                keyword: "",
                date: this.$options.filters.formatTime(new Date(), "yyyy-MM"),
            },
            tableData: [],
            page: {
                size: 15,
                page: 1,
                total: 0,
            },

            tableHeaderStyle: {
                backgroundColor: "#F5F8FA",
                textAlign: "center",
            },
            tableCellStyle: {
                textAlign: "center",
            },
        };
    },
    created() {
        this.loadData((loadDataObj) => {
            loadDataObj.month = this.search.date;
        });
    },
    methods: {
        ...mapActions("pay", ["getSalaryBorrowLogList"]),

        loadData(func) {
            // 单例初始化
            if (!this.loadDataObj)
                this.loadDataObj = new LoadDataClass(
                    0,
                    this.getSalaryBorrowLogList
                );

            // 通知，用于修改对象属性
            if (func) func(this.loadDataObj);

            let loading = this.$loading();
            this.loadDataObj
                .load(this.page.page)
                .then((res) => {
                    console.log(res);
                    this.tableData = res.data.data.list;
                    this.page.total = res.data.data.total;
                    loading.close();
                })
                .catch((e) => {
                    loading.close();
                    this.$message.error(e);
                });
        },

        /**
         * 搜索
         */
        toSearch() {
            this.page.page = 1;
            this.loadData((loadDataObj) => {
                loadDataObj.keyword = this.search.keyword;
                loadDataObj.month = this.search.date;
            });
        },
    },
};

class LoadDataClass {
    constructor(month, request, keyword = null) {
        this.month = month;
        this.keyword = keyword;
        this.func = request;
    }

    load(page) {
        let params = {
            month: this.month,
            page,
        };
        if (this.keyword) params.keyword = this.keyword;
        return this.func(params);
    }
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 14px;
    font-weight: bold;
    color: #1c2438;
}

.filter_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    .el-date-editor {
        height: 36px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
            height: 36px;
        }
    }

    .filter_search {
        display: flex;
        align-items: center;

        .filter_search_input {
            width: 350px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }
        }
    }

    .filter_btns {
        display: flex;
        align-items: center;

        > div:not(:first-child) {
            margin-left: 20px;
        }
    }
}

.operations {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        font-size: 14px;
        color: #406bff;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 13px;
        }
    }
}
</style>
